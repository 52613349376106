import { useState, useEffect, useRef } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloClient, NormalizedCacheObject, ApolloProvider } from '@apollo/client'
import { initializeApolloClient, initializeTranslations } from '~/core/apolloClient'
import { theme } from '~/styles'
import { inputGlobalStyles } from '~/styles/globalStyle'
import { CaslWrapper } from './casl_context'

const App = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null)

  const initApolloClient = async () => {
    const apolloClient = await initializeApolloClient()
    setClient(apolloClient)
  }

  useEffect(() => {
    // eslint-disable-next-line no-console
    initApolloClient().catch((err) => console.error('error', err))
    initializeTranslations()
  }, [])

  if (!client) return null

  return (
    <BrowserRouter basename="/">
      <ApolloProvider client={client}>
        <CaslWrapper theme={theme} inputGlobalStyles={inputGlobalStyles} />
      </ApolloProvider>
    </BrowserRouter>
  )
}

export default App

import { AnyAbility } from '@casl/ability'
import { createContext } from 'react'
import { useCurrentUser } from './hooks/useCurrentUser'
import { useRef } from 'react'
import { UserIdentifier } from '~/components/UserIdentifier'
import { ToastContainer } from '~/components/designSystem/Toasts'
import { ErrorBoundary } from '~/components/ErrorBoundary'
import { RouteWrapper } from '~/components/RouteWrapper'
import { useShortcuts } from '~/hooks/ui/useShortcuts'
import { DebugInfoDialog, DebugInfoDialogRef } from '~/components/DebugInfoDialog'
import { Theme, ThemeProvider } from '@mui/material'
import ability from './ability'

type CaslWrapperProps = {
  theme: Theme
  inputGlobalStyles: JSX.Element
}

export const AbilityContext = createContext({} as AnyAbility)

export const CaslWrapper = ({ theme, inputGlobalStyles }: CaslWrapperProps) => {
  const { currentUser } = useCurrentUser()
  const debugInfoDialogRef = useRef<DebugInfoDialogRef>(null)

  useShortcuts([
    {
      keys: ['Ctrl', 'KeyI'],
      action: () => debugInfoDialogRef.current?.openDialog(),
    },
  ])
  return (
    <AbilityContext.Provider value={ability(currentUser)}>
      <ThemeProvider theme={theme}>
        {inputGlobalStyles}
        <ErrorBoundary>
          <RouteWrapper />
        </ErrorBoundary>
        <UserIdentifier />
        <ToastContainer />
        <DebugInfoDialog ref={debugInfoDialogRef} />
      </ThemeProvider>
    </AbilityContext.Provider>
  )
}

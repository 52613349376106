import { AbilityBuilder, createMongoAbility } from '@casl/ability'
import { Role } from './generated/graphql'

const defineAbilityFor = (currentUser: any) => {
  const { can, build } = new AbilityBuilder(createMongoAbility)
  switch (currentUser?.role) {
    case Role.Admin:
      can('read', [
        'Dashboard',
        'Plans',
        'Customers',
        'Add-Ons',
        'Invoices',
        'Approvals',
        'Settings',
        'Design System',
      ])
      break
    case Role.Mod:
      can('read', ['Dashboard', 'Customers', 'Approvals', 'Settings'])
      break
    case Role.User:
      can('read', ['Dashboard', 'Plans', 'Customers', 'Add-Ons', 'Settings'])
      break
    default:
      can('read', ['Dashboard', 'Customers'])
      break
  }

  return build()
}
export default defineAbilityFor
